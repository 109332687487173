<template>
  <div class="huaiHuaInfo-page">
    <main-nav />

    <div class="huaiHuaInfo-content">
      <div class="ListNav">
        <h3 class="title">走进怀化</h3>
        <div class="itemWrapper">
          <div
            @click="changeOptions(0)"
            :class="[indexMark == 0 ? 'active' : '']"
          >
            行政区划
          </div>
          <div
            @click="changeOptions(1)"
            :class="[indexMark == 1 ? 'active' : '']"
          >
            自然地理
          </div>
          <div
            @click="changeOptions(2)"
            :class="[indexMark == 2 ? 'active' : '']"
          >
            环境资源
          </div>
        </div>
      </div>
      <div class="article">
          <div v-if="indexMark==0">
              <p class="title">行政区划</p>
              <p class="subTitle">发布时间：2021-04-29 08:38    信息来源：深圳市湖南怀化商会门户网站</p>
              <div class="content">
                1998年撤销怀化地区，改设地级怀化市。
              </div>
              <div class="content">
                现辖12个县（市、区）和一个管委会，包括鹤城区、中方县、洪江市、沅陵县、辰溪县、溆浦县、会同县、麻阳苗族自治县、新晃侗族自治县、芷江侗族自治县、靖州苗族侗族自治县、通道侗族自治县和洪江区管委会。
              </div>
              <div class="content">
                全市200个乡（其中民族乡21个）、86个镇、11个街道办事处，有275个社区居民委员会和3855个村民委员会。
              </div>
          </div>
          <div v-if="indexMark==1">
              <p class="title">自然地理</p>
              <p class="subTitle">发布时间：2021-04-29 09:26    信息来源：深圳市湖南怀化商会门户网站</p>
              <div class="content">
                【位置面积】怀化位于湖南西部偏南，处于武陵山脉和雪峰山脉之间，沅水自南向北贯穿全境，总面积27564平方千米，地处北纬25°52′22″～29°01′25″，东经108°47′13″～111°06′30″之间，南北长353km，东西宽229km，是湖南省面积最大的地级市。从地理位置上看，怀化南接广西（桂林、柳州），西连贵州（铜仁、黔东南），与湖南的邵阳、娄底、益阳、常德、张家界等市和湘西苗族土家族自治州接壤。怀化素有“黔滇门户”“全楚咽喉”之称，是中国东中部地区通往大西南的“桥头堡”，今有湖南“西大门”之美誉。
              </div>
              <div class="content">
                【地质地貌】怀化市地处上扬子地块与南华裂陷槽的过渡地带，区内地层发育齐全，构造运动、岩浆活动频繁，地层出露完整，从震旦纪至新生界沉积岩相构造齐全，岩性组成复杂。前震旦纪地层褶皱强烈，以断裂构造为主导，全市形成一系列北东和北北东深大断裂和大断裂为主体的断裂构造体系。境内矿产资源丰富，是湖南西部重要的能源及金属、非金属矿产基地。
              </div>
              <div class="content">
                境内山丘重叠，峰峦起伏，地形复杂。地貌轮廓自西南向北倾斜，呈一狭长地带。东南部雪峰山脉成弧形盘踞，西北部为武陵山脉所绵延，中间丘岗起伏，形成若干盆地。地势最高点为雪峰山脉之主峰苏宝顶，海拔标高1934米；最低为沅陵的界首，海拔仅45米。东西两侧高峻，南部突起，向中、北部倾斜，呈簸箕形，向北东敞口。
              </div>
              <div class="content">
                武陵山脉呈北东向绵延，北西坡陡，南东坡缓，其山脉北东部南东麓在区内延展约104千米，山脊走向与构造线方向基本一致。
              </div>
              <div class="content">
                雪峰山呈北东向展布，南东坡陡，北西坡缓，区内延展180千米，山脊构成区域分水岭，走向与构造线基本一致。
              </div>
              <div class="content">
                山地占全区总面积的70.5%，丘岗、岗地、平原、山原、水域分别占17.9%，、4.1%、4.2%、0.6%、2.7%。全区大致是“七山二丘岗，一份平原加水域”。海拔800米以上的主峰1149个，其中1000米以上的山峰663个。东西部以低山、中山为主；南部多为中低山；北部则以低山、中低山为主；中部主要发育低山、丘陵、平原。
              </div>
          </div>
          <div v-if="indexMark==2">
              <p class="title">环境资源</p>
              <p class="subTitle">发布时间：2021-02-23 09:18    信息来源：深圳市湖南怀化商会门户网站</p>
              <div class="content">
                怀化物产资源丰富。素有“广木之乡”、“水果之乡”、“药材之乡”的美誉。全市有活立木蓄积量8696万立方米，居湖南首位。有中药材26万亩，1900多个品种，其中175种为国家重点中药材保护品种，茯苓和天麻产量居全国第一。年产水果90多万吨。其中靖州的杨梅、溆浦的蜜枣、麻阳的冰糖橙久负盛名。探明矿藏11类45种，黄金、铜、磷储量分居湖南第一、三、四位，石煤、硅砂、重晶石储量居全国前列。水能理论蕴藏量500万千瓦，现已开发450万千瓦，为全国十大水电基地的主体地带之一。
              </div>
              <div class="content">
                水能资源：怀化水能资源丰富，是全国十大水电基地之一的主体地带，境内水能蕴藏量达499万千瓦，可开发水能450万千瓦，均居全省之首。湖南省目前装机容量最大的水能发电站——五强溪水电站坐落于境内沅水干流上。湖南“三湘四水”中著名的沅水自南向北贯穿境内，干流境内总长为568公里，流域面积达27239平方公里，境内重要的支流有酉水、辰水、溆水、舞水和渠水，古称“五溪”，因此怀化自古便称“五溪之地”。
              </div>
          </div>
      </div>
    </div>

    <back-to-top :custom-style="myBackToTopStyle" :visibility-height="300" :back-position="0" transition-name="fade" />

    <Footer />
  </div>
</template>

<script>
import MainNav from "@/components/MainNav/index";
import Footer from "@/components/Footer.vue";

export default {
  name: "HuaiHuaInfo",
  data() {
    return {
      myBackToTopStyle: {
        right: '50px',
        bottom: '50px',
        width: '6rem',
        height: '6rem',
        'border-radius': '50%',
        'line-height': '45px', // 请保持与高度一致以垂直居中
      },
      indexMark: this.$route.query.index,
    };
  },
  computed: {
  },
  components: {
    MainNav,
    Footer
  },
  mounted() {
  },
  methods: {
    changeOptions(index) {
      this.indexMark = index;
    },
  }
};
</script>

<style scoped lang="less">
.huaiHuaInfo-page {
  .huaiHuaInfo-content{
    max-width: 1060px;
    min-height: 500px;
    margin: 30px auto 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
      .ListNav {
        width: 18%;
        margin-right: 1%;
        color: #fff;
        min-height: 250px;
        border-radius: 10px;
        // background-position: center;
        background-image: url("../assets/humanities/leftMemuBg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        .title{
          text-align: center;
          font-size: 22px;
          font-family: emoji;
          color: #fff;
          border-bottom: 8px #FFF solid;
          padding: 10px 10px 15px 10px;
        }
        .itemWrapper {
          box-sizing: border-box;
          padding: 0px 10px 20px 10px;
          width: 100%;
          div {
            margin: 20px 5px;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            padding: 12px 5px;
            font-size: 15px;
            color: #b0c2ee;
            cursor: pointer;
            &:hover {
              background-color: rgb(135, 135, 243);
            }

          }
          .active {
            background-color: #FFF;
            border-bottom: 0.33rem solid #fbde00;
            color: black;
          }
        }
      }
      .article{
          width:70%;
          .title{
              font-size: 30px;
              font-weight: 600;
              color: #0066b3;
              text-align: center;
              padding: 0 32px 16px;
          }
          .subTitle{
              padding: 0 10px;
              font-size: 14px;
              font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
              text-align: center;
              margin-bottom: 30px;
          }
          .content{
            color: #3d3d3d;
            line-height: 34px;
            text-indent: 2em;
            margin-top: 15px;
          }
      }
  }
      

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>